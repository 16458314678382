@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
body{
	margin: 0;
}
html,
body,
#root {
	min-height: 100vh;
	background-color: lightgray;
	--adm-font-size-main: var(--adm-font-size-6);
}
.adm-popup-body-position-bottom {
	max-width: 42rem;

	left: 0;
	right: 0;

	margin-left: auto;
	margin-right: auto;
}
