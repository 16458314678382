:root:root {
	--adm-color-primary: #e71316;
}

/* .adm-tabs-tab-wrapper {
	flex: 1;
	overflow: hidden;
} */
.adm-tabs-tab,
.adm-cascader-view-header-title {
	max-width: 100% !important;
	text-align: center;
}

.form_popup .adm-list-body {
	border-bottom: none !important;
}
.form_popup .adm-list-item-content {
	border-top: none;
}
